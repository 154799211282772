import React from "react";
import './FaqPage.scss';
import Arrow2 from '../../Images/next.png'
import Review from '../../Images/Review.png'
import next from '../../Images/next.png'
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// images
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function FaqPage({ Blogs, FaqList }) {
    const location = useLocation()
    const [SpecificFaq, setstate] = useState(FaqList[location.pathname.split('_').slice(-1).pop()]);
    const navigate = useNavigate()

    const getSpecificNumber = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumber()
        }
    }
    const [getNumber, setgetNumber] = useState(getSpecificNumber());

    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());
    return (

        <div className="FaqPage">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Ventura, CA Heating & Air Conditioning | {SpecificFaq.questionPath}</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content={`Ventura Heating & Air Conditioning | ${SpecificFaq.questionPath}`} data-react-helmet="true" />
                <meta name="og:title" property="og:title" content={`Ventura Heating & Air Conditioning | ${SpecificFaq.questionPath}`} data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="articleId" content={SpecificFaq.faqId} />
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content={SpecificFaq.question} />
                <meta name="SubCategories" content={SpecificFaq.questionPath} />
                <meta name="FunnelStage" content="upper" />
                <meta name="Published" content="20240410" />
                <meta name="Modified" content="20240410" />
                <meta name="Objective" content="education, seo" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content={`https://www.venturahvacairconditioning.org/faq/${SpecificFaq.questionPath.replace(/ /g, "_") + "_" + (SpecificFaq.BlogId - 1)}`} data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the Ventura Heating & Air Conditioning Faq Page for insightful articles on strategies, tips, and expert advice. Discover innovative ways to Heating & Air Conditioning services, secure your home." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the Ventura Heating & Air Conditioning Faq Page for insightful articles on strategies, tips, and expert advice. Discover innovative ways to Heating & Air Conditioning services, secure your home." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content={`Ventura Heating & Air Conditioning | ${SpecificFaq.questionPath}`} data-react-helmet="true" />
                <meta name="keywords" content="HVAC services Ventura CA, Air conditioning repair Ventura, Heating repair Ventura CA, HVAC installation Ventura, Furnace repair Ventura, AC maintenance Ventura CA, Air duct cleaning Ventura, Emergency HVAC services Ventura, HVAC contractors Ventura CA, Residential HVAC Ventura, Commercial HVAC Ventura, HVAC tune-up Ventura, AC replacement Ventura, Heating system installation Ventura, Indoor air quality Ventura CA, HVAC repair near me Ventura, Central air conditioning Ventura, Ductless mini-split Ventura, HVAC service plan Ventura, Thermostat installation Ventura, 24/7 HVAC service Ventura, Energy-efficient HVAC Ventura, Air filter replacement Ventura, Heat pump repair Ventura, HVAC maintenance Ventura CA, HVAC inspection Ventura, Air conditioner installation Ventura, Heating and cooling Ventura, AC service Ventura CA, HVAC system replacement Ventura, Furnace installation Ventura CA, HVAC repair cost Ventura, Local HVAC company Ventura, Affordable HVAC Ventura, AC troubleshooting Ventura, HVAC technicians Ventura CA, HVAC emergency repair Ventura, Heater repair Ventura CA, Air conditioning services Ventura, Commercial air conditioning Ventura, Furnace tune-up Ventura, Ductwork repair Ventura CA, HVAC financing Ventura, HVAC deals Ventura CA, Heating and air conditioning Ventura, HVAC consultation Ventura, Central heating repair Ventura, HVAC contractor near me Ventura, Reliable HVAC Ventura CA, Licensed HVAC contractor Ventura" data-react-helmet="true" />
            </Helmet>
            <article>

                <div className="FaqPageMainBanner">
                    <h1>Explore the Ventura Heating & Air Conditioning Faq Page</h1>
                    <img onClick={() => navigate(`/faq`)}src={Arrow2} alt="" />
                </div>

                <div className="FaqPageListBox">
                    <h1>{SpecificFaq.question}</h1>
                    <h2>{SpecificFaq.answer}</h2>
                    <h3><span> {SpecificFaq.category}</span></h3>
                </div>
                <div className="FaqPageListBoxMenu">
                    <div className="FaqPageListBoxMenuBox">
                        {FaqList.find(a => a.faqId == SpecificFaq.faqId).faqId != 1 ?
                            <a href={`/faq/${FaqList.find(a => a.faqId == SpecificFaq.faqId - 1).questionPath.replace(/ /g, "_") + "_" + (FaqList.find(a => a.faqId == SpecificFaq.faqId - 1).faqId - 1)}`}>
                                <img src={next} alt="" />
                                <h3> Previous</h3>
                            </a>
                            :
                            null
                        }

                    </div>
                    <div className="FaqPageListBoxMenuBox">
                        {FaqList.find(a => a.faqId == SpecificFaq.faqId).faqId != (FaqList.length) ?
                            <a href={`/faq/${FaqList.find(a => a.faqId == SpecificFaq.faqId + 1).questionPath.replace(/ /g, "_") + "_" + (FaqList.find(a => a.faqId == SpecificFaq.faqId + 1).faqId - 1)}`}>
                                <h3>Next</h3>
                                <img src={next} alt="" />
                            </a>
                            :
                            null
                        }

                    </div>
                </div>

                <div className="FaqListReview">
                    <div className="FaqListReviewHeader">
                        <h6>Frequently asked questions</h6>
                    </div>
                    <div className="FaqListReviewList">

                        {FaqList.slice(getNumber, (getNumber + 3)).map((A, index) =>
                            <article key={index} className="FaqListBoxReview">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                    </div>
                </div>

                <div className="BlogsListReview">
                    <div className="BlogsListReviewHead">
                        <h1>Related Articles</h1>
                        <a href="/blog">View All</a>

                    </div>
                    <div className="BlogsListReviewList">
                        {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>
                            <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                                <article>


                                    <div className="BlogsListReviewBoxLeft">
                                        <img src={A.Photos} alt={A.BlogPath} />
                                    </div>
                                    <div className="BlogsListReviewBoxRight">
                                        <h1>{A.Title}</h1>
                                        <span>
                                            {/* <h5>{readingTime(index)} MIN READ</h5> */}
                                            <h4>{A.Date}</h4>
                                        </span>
                                    </div>
                                </article>

                            </a>
                        )}
                    </div>
                </div>
                <NavigatorPath />
            </article>

        </div >
    )
}

